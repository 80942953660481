$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': config.csrf_token
    }
});

// Needs to go before datatables is initialised!
var $jsDtColumns = $('.js-dt-columns-toggle');
if ($jsDtColumns.length) {
    var $table = $($jsDtColumns.data('target'));
    $.each($('th', $table), function (i) {
        var li = $('<li/>')
            .appendTo($jsDtColumns);

        var a = $('<a/>')
            .attr('data-column', i)
            .text($(this).text())
            .appendTo(li);
    });
}

// Toggle column visibilty.
$('.js-dt-columns-toggle a').on('click', function (e) {
    var datatable = $table.DataTable();
    var column = datatable.column($(this).attr('data-column'));

    column.visible(!column.visible());

    return false;
});

// Reset datatables.
$('a.js-dt-reset').on('click', function (e) {
    var $table = $($(this).data('target'));
    var datatable = $table.DataTable();

    datatable.state.clear();
    location.reload();
});

// Allows the user to search in the sidebar menu as well as the DT search box.
$('.js-dt-search').on('keyup', function() {
    var $table = $($(this).data('target'));
    var datatable = $table.DataTable();
    datatable.search($(this).val()).draw();
});

$('.datatables').DataTable({
    "bStateSave": true,
    "aaSorting": []
});

// See changelog :( "No more hidden input tags" https://select2.github.io/announcements-4.0.html#hidden-input
// Using 3.5.1 from Sodium L4/5 as to use the latest version would require a big rewrite (F + BE).
$('select.js-select').select2({
    allowClear: true
});

var tags = function () {
    /**
     * ===================
     * Select2 tags fields
     * ===================
     * Make a hidden input with the Form::tagsField() php method
     * Give it a class of .js-tags to attach the tagger to it
     * To make it drag'n'drop sortable add .js-tags-sortable as an additional class to the hidden input
     * Set data-tags to a json encoded string of all available tags.
     * Represented as a php array it must be formatted as:
     * [ ['id' => 'tag1','text' => 'tag1'], ['id' => 'tag2','text' => 'tag2'] ]
     * As Json:
     * [{"id":"tag1","text":"tag1"},{"id":"tag2","text":"tag2"},{"id":"tag3","text":"tag3"}]
     */

    var $tagsField = $('.js-tags'); //Sodium.settings.getSelector('tags')
    var tagsSeparator = '|';

    function makeTags($element, tags) {
        var opts = {
            tags: tags,
            separator: tagsSeparator,
            tokenSeparators: [tagsSeparator],
            createSearchChoice: function (term) {
                if ($element.data('disable-create') === true) {
                    return false;
                }
                if ($element.data('force-lower') === true) {
                    return {
                        id: term.trim().toLowerCase(),
                        text: term.trim().toLowerCase()
                    };
                } else {
                    return {
                        id: term.trim(),
                        text: term.trim()
                    };
                }
            }
        };

        var limit = $element.data('limit');
        if (limit) {
            opts.maximumSelectionSize = limit;
        }

        $element.select2(opts);
        $element.select2('enable', true);

        if ($element.hasClass('js-tags-sortable')) {
            $element.select2('container').find('ul.select2-choices').sortable({
                containment: 'parent',
                start: function () {
                    $element.select2('onSortStart');
                },
                stop: function () {
                    $element.select2('onSortEnd');
                }
            });
        }
    }

    $tagsField.select2({tags: []});
    $tagsField.select2('enable', false);

    $tagsField.each(function () {
        var $that = $(this);
        if ($that.data('tags')) {
            makeTags($that, $that.data('tags'));
        } else if ($that.data('tags-url')) {
            $.get($that.data('tags-url')).done(function (data) {
                makeTags($that, data);
            }, 'json');
        }
    });
}
tags();

/*
 * Ajax field loader
 */
$('.js-load-fields').on('change', function(e) {

    var $that = $(e.target),
        $target = $($that.data('load-fields-target')),
        url = $that.data('load-fields-href'),
        params = JSON.parse($that.attr('data-load-fields-params')),
        value = $that.val();

    //openLoadingOverlay($target);

    var newParams = params;
    $.each(newParams, function(idx, v) {
        if (v === ':val') newParams[idx] = value;
    });

    $.ajax({
        url: url,
        type: 'get',
        data: params,
        success: function (data) {
            $target.html(data);
            tags();
            initRedactor();
            initCropper();
            initDatepicker();
            toggleContentEditor();
        }
    });
});

function toggleContentEditor()
{
    var enableContent = $('div[data-js-enable-content="1"]').length;
    $('input[name="enable_content"]').prop('checked', enableContent).change();
}
toggleContentEditor();

/*
 * Toggle element
 */
$('[data-toggle-element]').on('change', function(e) {
    var $current = $(e.currentTarget),
        $target = $($current.attr('data-toggle-element'));

    if ($current.is(':checked')) {
        $target.show();
    } else {
        $target.hide();
    }
});
$('[data-toggle-element]').trigger('change');

$('.js-sortable').sortable({
    opacity: 0.2,
    items: '.js-sortable-item',
    handle: '.handle',
    cursor: 'move',
    update: function() {
        var $that = $(this),
            url = $that.data('href'),
            data = $that.sortable('serialize');

        if (url !== undefined) {
            $.post(url, data, function(response) {
                console.log(response);
            }).success(function() {
                swal("Sweet", "Your items have been reordered.", "success");
            });
        }
    }
});

// Custom - to be made more "element" specific to increase efficiency.
$(document).on('click', '.js-delete', function(e) {
    var $this = $(this);
    swal({
        title: $this.data('title'),
        text: $this.data('text'),
        type: $this.data('type') ? $this.data('type') : "warning",
        showCancelButton: true,
        confirmButtonColor: $this.data('confirm-button-color') ? $this.data('confirm-button-color') : "#DD6B55",
        confirmButtonText: $this.data('confirm-button-text') ? $this.data('confirm-button-text') : "Delete",
        closeOnConfirm: false
    }, function (isConfirm) {
        if (isConfirm) {
            $.ajax({
                type: 'POST',
                url: $this.attr('href'),
                data: { _method: 'DELETE' }
            }).done(function() {
                var $target = $this.data('target') ? $this.data('target') : $this;
                $this.closest($target).fadeOut();

                swal({
                    title: $this.data('success-title'),
                    text: $this.data('success-text'),
                    type: "success"
                }, function () {
                    var reload = $this.data('reload') ? $this.data('reload') : true;
                    if (reload) {
                        location.reload();
                    }
                });
            });
        }
    });

    return false;
});

function initRedactor()
{
    // Merges "config" with our own settings.
    $.extend(config.redactor, {
        plugins: ['imagemanager', 'filemanager', 'video']
    });

    $('.wysiwyg').each(function () {
        var $this = $(this);
        if (!$this.closest('.redactor-box').length) {
            $this.redactor(config.redactor);
        }
    });
}
initRedactor();

// Select the first option in a select if not selected and only contains one option.
if ($('.js-select-first option:selected').val() === "") {

    var $select = $('select.js-select-first');
    var values = [];

    $('option', $select).each(function (i, v) {
        if (v.value) {
            values.push(v.value);
        }
    });

    if (values.length === 1) {
        $select.val(values[0]).trigger('change');
    }
}

$('.js-sluggify').keyup(function () {
    var $this = $(this);
    var string = $this.val();
    var target = $($this.data('target'));

    target.val(convertToSlug(string));
});

function convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}

$('.js-disable-inputs :input').prop("disabled", true);

function initCropper()
{
    $('.cropper').sodiumCropper();
}
initCropper();

function initDatepicker()
{
    $('.js-datepicker').datepicker({
        dateFormat: "dd/mm/yy"
    });
}
initDatepicker();

var nestedSortable = $('.js-nested-sortable');
nestedSortable.nestedSortable({
    handle: 'div',
    items: 'li',
    toleranceElement: '> div',
    maxLevels: 2
});

$('.js-nested-update').on('click', function () {
    var nested_items = nestedSortable.nestedSortable('serialize');
    var url = nestedSortable.parent('div').data('href');

    if (url) {
        $.ajax({
            url: url,
            type: 'POST',
            data: nested_items,
            success: function () {
                swal("Order", "Your items have been reordered.", "success");
            }
        });
    }
});

// Page titles (not indexed)
$(document).attr("title", $('h1:first').contents().filter(function() {
    return this.nodeType == Node.TEXT_NODE;
}).text());
